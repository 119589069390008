var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
import createStudentFromEnquiry from "../../../../api/mutations/createStudentFromEnquiry";
import SaveForNextTimePage from "../../general/SaveForNextTimePage.vue";
export default Vue.extend({
    components: {
        SaveForNextTimePage: SaveForNextTimePage,
    },
    apollo: {
        propertyEnquiryExists: {
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query EnquirySaveForNextQuery($id: ID!) {\n          propertyEnquiryExists(id: $id)\n        }\n      "], ["\n        query EnquirySaveForNextQuery($id: ID!) {\n          propertyEnquiryExists(id: $id)\n        }\n      "]))),
            variables: function () {
                return {
                    id: this.$route.params.enquiryId,
                };
            },
        },
    },
    methods: {
        process: function (password) {
            return this.$apollo
                .mutate(createStudentFromEnquiry({
                enquiry: this.$route.params.enquiryId,
                password: password,
            }))
                .then(function () { return undefined; });
        },
    },
});
var templateObject_1;
